import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  .children-container {
    width: calc(100% - 320px); /* 320px is leftbar width */
    height: calc(100% - 56px); /* 56px is header height*/
    left: 320px; /* 56px is leftbar width */
    top: 56px; /* 56px is header height*/
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    background: #000000;
    color: white;
    padding: 48px 64px;
  }
  .cv-status-caution-container {
    margin-bottom: 32px;
  }
  .page-container {
    width: 1472px;
    margin: 0 auto;
  }
  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    .children-container {
      padding: 48px 32px;
    }
    .page-container {
      width: 1055px;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .children-container {
      width: 100%;
      left: 0px;
      padding: 48px 32px;
    }
    .page-container {
      width: 1216px;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: ${device.maxSizeS}) {
    .children-container {
      width: 100%;
      left: 0px;
      padding: 48px 16px;
    }
    .page-container {
      width: 100%;
    }
  }
`
