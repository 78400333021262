import React from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { toast } from 'react-toastify'
import queryString from 'query-string'
import moment from 'moment'
import { i18n, withTranslation } from '../../../i18n'

import Header from '../../components/Header/dynamic'
import LeftBarNavigation from '../../components/LeftBarNavigation/dynamic'
import DialogModal from '../../components/DialogModal/dynamic'
import InfiniteScroll from '../../components/InfiniteScroll/dynamic'
import CVStatusModal from '../../components/CVStatusModal/dynamic'
import Notification from '../../components/Notification/dynamic'

import * as AuthActions from '../../actions/auth'

import { storage, capitalize, findMatchPermissionsAtLeastSuperUser, FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS } from '../../utils'
import { INCIDENT_PAGE_PATH } from '../../routes'

import ApplicationStyled from './styled'

import LogoutDialogIcon from '../../assets/images/dialog-modal/log-out.svg'

const SECURITY_GUARD_ROLE_NAME = 'Security Guard'
const CAMERA_TYPE_ENTRANCE = 'entrance'
const CAMERA_TYPE_EXIT = 'exit'

export class ApplicationLayout extends React.PureComponent {
  state = {
    isLogoutModalOpen: false,
    isLeftBarExpand: false,
    showNotificationBadge: false,
  }

  componentDidMount() {
    i18n.changeLanguage(this.props.language.toLowerCase())
  }

  componentDidUpdate(prevProps) {
    this.handleLanguageChanged(prevProps)
    this.handleCreateTenant(prevProps)
    this.handleUpdateTenant(prevProps)
    this.handleDeleteTenant(prevProps)
    this.handleCreateMember(prevProps)
    this.handleUpdateMember(prevProps)
    this.handleDeleteMember(prevProps)
    this.handleIncidentNotification(prevProps)
    this.handleCreateUser(prevProps)
    this.handleUpdateUser(prevProps)
    this.handleDeleteUser(prevProps)
    this.handleCreateCameraWebhook(prevProps)
    this.handleUpdateCameraWebhook(prevProps)
    this.handleDeleteCameraWebhook(prevProps)
  }

  handleLanguageChanged(prevProps) {
    if (prevProps.language !== this.props.language) {
      i18n.changeLanguage(this.props.language.toLowerCase())
    }
  }

  handleCreateTenant = prevProps => {
    if (prevProps.tenant.createTenant.fetchStatus === FETCH_STATUS_REQUEST && this.props.tenant.createTenant.fetchStatus === FETCH_STATUS_SUCCESS) {
      toast(<Notification incidenceType="tenant_update" message={this.props.t('tenant_has_been_created')} title={this.props.t('successful')} />)
    }
  }

  handleUpdateTenant = prevProps => {
    if (prevProps.tenant.updateTenant.fetchStatus === FETCH_STATUS_REQUEST && this.props.tenant.updateTenant.fetchStatus === FETCH_STATUS_SUCCESS) {
      toast(<Notification incidenceType="tenant_update" message={this.props.t('information_has_been_edited')} title={this.props.t('successful')} />)
    }
  }

  handleDeleteTenant = prevProps => {
    if (prevProps.tenant.deleteTenant.fetchStatus === FETCH_STATUS_REQUEST && this.props.tenant.deleteTenant.fetchStatus === FETCH_STATUS_SUCCESS) {
      toast(<Notification incidenceType="tenant_update" message={this.props.t('tenant_has_been_deleted')} title={this.props.t('successful')} />)
    }
  }

  handleCreateMember = prevProps => {
    if (prevProps.member.createMember.fetchStatus === FETCH_STATUS_REQUEST && this.props.member.createMember.fetchStatus === FETCH_STATUS_SUCCESS) {
      toast(<Notification incidenceType="member_update" message={this.props.t('member_has_been_created')} title={this.props.t('successful')} />)
    }
  }

  handleUpdateMember = prevProps => {
    if (prevProps.member.updateMember.fetchStatus === FETCH_STATUS_REQUEST && this.props.member.updateMember.fetchStatus === FETCH_STATUS_SUCCESS) {
      toast(<Notification incidenceType="member_update" message={this.props.t('information_has_been_edited')} title={this.props.t('successful')} />)
    }
  }

  handleDeleteMember = prevProps => {
    if (prevProps.member.deleteMember.fetchStatus === FETCH_STATUS_REQUEST && this.props.member.deleteMember.fetchStatus === FETCH_STATUS_SUCCESS) {
      toast(<Notification incidenceType="member_update" message={this.props.t('member_has_been_deleted')} title={this.props.t('successful')} />)
    }
  }

  handleCreateUser = prevProps => {
    if (
      prevProps.userManagement.registerUser.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagement.registerUser.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      toast(<Notification incidenceType="user_update" message={this.props.t('user_has_been_created')} title={this.props.t('successful')} />)
    }
  }

  handleUpdateUser = prevProps => {
    if (
      prevProps.userManagement.updateUser.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagement.updateUser.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      toast(<Notification incidenceType="user_update" message={this.props.t('information_has_been_edited')} title={this.props.t('successful')} />)
    }
  }

  handleDeleteUser = prevProps => {
    if (
      prevProps.userManagement.deleteUser.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.userManagement.deleteUser.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      toast(<Notification incidenceType="user_update" message={this.props.t('user_has_been_deleted')} title={this.props.t('successful')} />)
    }
  }

  handleCreateCameraWebhook = prevProps => {
    if (
      prevProps.setting.createCameraWebhook.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.setting.createCameraWebhook.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      toast(<Notification incidenceType="url_update" message={this.props.t('url_has_been_created')} title={this.props.t('successful')} />)
    }
  }

  handleUpdateCameraWebhook = prevProps => {
    if (
      prevProps.setting.updateCameraWebhook.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.setting.updateCameraWebhook.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      toast(<Notification incidenceType="url_update" message={this.props.t('information_has_been_edited')} title={this.props.t('successful')} />)
    }
  }

  handleDeleteCameraWebhook = prevProps => {
    if (
      prevProps.setting.deleteCameraWebhook.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.setting.deleteCameraWebhook.fetchStatus === FETCH_STATUS_SUCCESS
    ) {
      toast(<Notification incidenceType="url_update" message={this.props.t('url_has_been_deleted')} title={this.props.t('successful')} />)
    }
  }

  handleIncidentNotificationClicked = () => {
    const query = queryString.stringify({
      date: moment(this.props.listNotification[0].metadata.timestamp).format('YYYY-MM-DD'),
      zone: this.props.listNotification[0].metadata.zoneId,
      loadHeatMap: true,
    })
    this.setState({
      showNotificationBadge: false,
    })
    Router.push(`${INCIDENT_PAGE_PATH}?${query}`)
  }

  handleIncidentNotification = prevProps => {
    if (
      this.props.loadNotification.fetchStatus === FETCH_STATUS_IDLE &&
      prevProps.listNotification.length !== this.props.listNotification.length &&
      this.props.user.permissions.includes('read:incident')
    ) {
      this.setState({
        showNotificationBadge: true,
      })
      toast(
        <Notification
          incidenceType={this.props.listNotification[0].metadata.incidentType}
          message={`Unauthorized access ${this.props.listNotification[0].metadata.zoneName}`}
          timestamp={this.props.listNotification[0].metadata.timestamp}
          title={`${capitalize(this.props.listNotification[0].metadata.incidentType)} Incident`}
        />,
        {
          onClick: this.handleIncidentNotificationClicked,
        },
      )
    }
  }

  closeLogoutModal = () => {
    this.setState({
      isLogoutModalOpen: false,
    })
  }

  openLogoutModal = () => {
    this.setState({
      isLogoutModalOpen: true,
    })
  }

  handleConfirmLogoutClick = () => {
    Router.push('/api/auth/logout')
    storage.remove('email')
    this.closeLogoutModal()
  }

  renderLogoutModal() {
    let output = null
    if (this.state.isLogoutModalOpen) {
      output = (
        <DialogModal
          id="logout-dialog-modal"
          className="logout-modal"
          imgSrc={LogoutDialogIcon}
          title={this.props.t('confirm_log_out')}
          content={this.props.t('are_you_sure_want_to_log_out_system')}
          primaryBtnOnClick={this.handleConfirmLogoutClick}
          primaryBtnText={this.props.t('log_out')}
          onOverlayClick={this.closeLogoutModal}
        />
      )
    }
    return output
  }

  toggleLeftBar = () => {
    this.setState({
      isLeftBarExpand: !this.state.isLeftBarExpand,
    })
  }

  handleLanguageChange = language => {
    const data = {
      locale: language,
    }
    this.props.updateUserProfile(data)
  }

  hideNotificationBadge = () => {
    this.setState({
      showNotificationBadge: false,
    })
  }

  getPreparedSystemCaution() {
    let preparedCaution
    const { systemHealthData } = this.props.systemHealth
    if (systemHealthData?.isSystemActive === false) {
      const isCameraError = !systemHealthData.cameras.isActive
      const isBarrierGateError = !systemHealthData.systems.isBarrierGateActive
      const isMemberBarrierGateError = !systemHealthData.systems.isMemberBarrierGateActive
      const isSmartcityClientError = !systemHealthData.systems.isSmartcityClientActive
      const isSecurityGuardRole = this.props.user.role.name === SECURITY_GUARD_ROLE_NAME
      const isCurrentCameraEntranceExitError = systemHealthData.cameras.data.reduce((prev, camera) => {
        if ([CAMERA_TYPE_ENTRANCE, CAMERA_TYPE_EXIT].includes(camera.type) && camera.id === this.props.visitor.loadListTodayVisitor.camera) {
          return prev || !!camera.inactiveAt
        }
        return prev
      }, false)

      if (isSmartcityClientError) {
        preparedCaution = {
          title: this.props.t('camera_connection_error'),
          message:
            isSecurityGuardRole && this.props.isRealTimeOperation
              ? this.props.t('please_note_visitor_and_open_barrier_gate_manually')
              : this.props.t('please_check_system_status_and_contact_sertis_support'),
        }
      } else if (isCameraError && ((isSecurityGuardRole && !this.props.isRealTimeOperation) || !isSecurityGuardRole)) {
        preparedCaution = {
          title: this.props.t('camera_connection_error'),
          message: this.props.t('please_check_system_status_and_contact_sertis_support'),
        }
      } else if (isCurrentCameraEntranceExitError) {
        preparedCaution = {
          title: this.props.t('camera_connection_error'),
          message: this.props.t('please_note_visitor_and_open_barrier_gate_manually'),
        }
      } else if (isBarrierGateError) {
        preparedCaution = {
          title: this.props.t('barrier_gate_connection_error'),
          message: isSecurityGuardRole
            ? this.props.t('please_check_and_open_barrier_gate_manually')
            : this.props.t('please_check_system_status_and_contact_sertis_support'),
        }
      } else if (isMemberBarrierGateError) {
        preparedCaution = {
          title: this.props.t('member_barrier_gate_cannot_open_automatically'),
          message: isSecurityGuardRole
            ? this.props.t('please_open_member_barrier_gate_manually_or_submit_by_app')
            : this.props.t('please_check_system_status_and_contact_sertis_support'),
        }
      }
    }
    return preparedCaution
  }

  isSuperUser() {
    const matchPermissions = findMatchPermissionsAtLeastSuperUser(['superuser'], this.props.user?.permissions)
    return matchPermissions.length > 0
  }

  getCautionSystemHealth() {
    let output = null
    const cautionData = this.getPreparedSystemCaution()
    if (cautionData) {
      output = (
        <CVStatusModal id="cv-status-caution" className="cv-status-caution-container" title={cautionData.title} message={cautionData.message} />
      )
    }
    return output
  }

  render() {
    return (
      <ApplicationStyled id={this.props.id} data-test={this.props.id}>
        <Header
          user={this.props.user}
          showNotificationBadge={this.state.showNotificationBadge}
          listNotification={this.props.listNotification}
          onHideNotificationBadge={this.hideNotificationBadge}
          onHamburgerClick={this.toggleLeftBar}
          openLogoutModal={this.openLogoutModal}
          onLanguageChanged={this.handleLanguageChange}
          language={this.props.language}
        />
        <LeftBarNavigation user={this.props.user} isExpand={this.state.isLeftBarExpand} onToggleLeftBar={this.toggleLeftBar} />
        <InfiniteScroll
          id={`${this.props.id}-children-content`}
          data-test={`${this.props.id}-children-content`}
          className={'children-container'}
          onScrollCall={this.props.onScrollCall}
        >
          {this.isSuperUser() ? this.getCautionSystemHealth() : null}
          <div className="page-container">{this.props.children}</div>
        </InfiniteScroll>
        {this.renderLogoutModal()}
      </ApplicationStyled>
    )
  }
}

ApplicationLayout.defaultProps = {
  id: 'application-layout',
  language: 'EN',
  isRealTimeOperation: false,
}

ApplicationLayout.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    nickname: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    locale: PropTypes.string,
    updated_at: PropTypes.string,
    sub: PropTypes.string,
    permissions: PropTypes.array,
    role: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  listNotification: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string, PropTypes.number]),
  updateUserProfile: PropTypes.func.isRequired,
  onScrollCall: PropTypes.func,
  loadNotification: PropTypes.shape({
    fetchStatus: PropTypes.string,
    error: PropTypes.string,
  }),
  tenant: PropTypes.shape({
    createTenant: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    updateTenant: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    deleteTenant: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  member: PropTypes.shape({
    createMember: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    updateMember: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    deleteMember: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  userManagement: PropTypes.shape({
    registerUser: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    updateUser: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    deleteUser: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  setting: PropTypes.shape({
    createCameraWebhook: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    updateCameraWebhook: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
    deleteCameraWebhook: PropTypes.shape({
      fetchStatus: PropTypes.string,
      error: PropTypes.string,
    }),
  }),
  visitor: PropTypes.shape({
    loadListTodayVisitor: PropTypes.shape({
      camera: PropTypes.number,
    }),
  }),
  systemHealth: PropTypes.shape({
    systemHealthData: PropTypes.shape({
      isSystemActive: PropTypes.bool,
      cameras: PropTypes.shape({
        isActive: PropTypes.bool,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            inactiveAt: PropTypes.string,
          }),
        ),
      }),
      systems: PropTypes.shape({
        isBarrierGateActive: PropTypes.bool,
        isMemberBarrierGateActive: PropTypes.bool,
        isSmartcityClientActive: PropTypes.bool,
      }),
    }),
  }),
  isRealTimeOperation: PropTypes.bool,
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    tenant: state.tenant,
    member: state.member,
    user: state.auth.session?.user,
    visitor: state.visitor,
    language: state.auth.session?.user.locale,
    listNotification: state.application.listNotification,
    loadNotification: state.application.loadListNotification,
    userManagement: state.userManagement,
    setting: state.setting,
    systemHealth: state.systemHealth,
  }
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...AuthActions }, dispatch)
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ApplicationLayout)
